export const caseTypesPakistan = [
  'Civil Cases',
  'Criminal Cases',
  'Family Cases',
  'Property Cases',
  'Commercial Cases',
  'Constitutional Cases',
  'Administrative Cases',
  'Contractual Disputes',
  'Labor and Employment Cases',
  'Tax Cases',
  'Intellectual Property Cases',
  'Environmental Cases',
  'Consumer Protection Cases',
  'Banking and Finance Cases',
  'Insurance Cases',
  'Immigration Cases',
  'Human Rights Cases',
  'Landlord-Tenant Disputes',
  'Personal Injury Cases',
  'Medical Malpractice Cases',
  'Defamation Cases',
  'Cybercrime Cases',
  'Juvenile Cases',
  'Arbitration Cases',
  'International Trade Disputes',
  // Add more case types as needed
];
